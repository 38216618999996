import HelloSign from 'hellosign-embedded';

const client = new HelloSign();

var url = document.querySelector('#hellosign');
if (null!==url){
    console.log(url.dataset.signurl)

    const signUrl=url.dataset.signurl;
    client.open(signUrl, {
        testMode: true,
        clientId: '4257d3d16012dfe3685a11937c4feb9d',
        locale: HelloSign.locales.FR_FR
    });
}


