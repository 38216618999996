$R('.content-ta', {
    imageUpload: Routing.generate('sw_fileuploadhandler'),
    imageResizable: true,
    imagePosition: true,
    plugins: ['counter', 'alignment'],
    buttonsAddBefore: {
        before: 'format',
        buttons: ['undo', 'redo']
    }
});
$R('.simple-ta', {

    plugins: ['counter'],

    buttons: ['bold', 'italic']

});

$R('.commentaire-ta', {
    plugins: ['counter'],
    buttonsAddBefore: {
        before: 'format',
        buttons: ['undo', 'redo']
    }
});


$R('.ecriveznous', {plugins: ['counter'], air: true, buttonsHide: ['html', 'deleted', 'link']});
