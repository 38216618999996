/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


require('../css/WingmanTheme/scss/theme.scss');

// require('../fontawesome-free-5.10.2-web/css/all.css');
require('../css/entypo.css');
require('../css/socicon.css');
require('./formvalidation-dist-v1.6.0/dist/css/formValidation.min.css');

// JS pour le thème

require('../js/bootstrap/index');
require('../css/WingmanTheme/dist/assets/js/theme');


// JS pour le site
require('../js/app_select2');
require('../js/file_bootstrap');

require('../js/add-collection-widget');
require('../redactor/redactor.min');
require('../redactor/_plugins/counter/counter.min');
require('../redactor/_plugins/alignment/alignment.min');
require('../js/textarea-redactor');
require('../js/flatpickr');
require('../redactor/redactor.min.css');
require('../select2/dist/css/select2.min.css');
require('../select2/dist/js/select2.full');
require('../select2/dist/js/i18n/fr');
require('../select2/dist/js/i18n/fr');

require('./fvalidator')

/*require("cookieconsent/build/cookieconsent.min.css")
require("cookieconsent")*/
 require("./helloSign")
// require('./cookies');

/* FORM VALIDATION */

global.FormValidation = require('./formvalidation-dist-v1.6.0/dist/js/FormValidation.full');
global.FormValidation.plugins.Bootstrap = require('./formvalidation-dist-v1.6.0/dist/js/plugins/Bootstrap');
global.FormValidation.locales.fr_FR = require('./formvalidation-dist-v1.6.0/dist/js/locales/fr_FR');


const $ = require('jquery');
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

