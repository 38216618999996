
$("#site_web_realisation_image_imageFile_file").change(function () {
    $("#preview_image").html("");
    readURL(this,'#preview_image');
});

function readURL(input,gallery) {
    console.log(input.files)
    for (var i = 0; i < input.files.length; i++) {
        if (input.files[i]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                var img = $('<img  class="rounded avatar avatar-lg mb-2">');
                img.attr('src', e.target.result);
                img.appendTo(gallery);
            }
            reader.readAsDataURL(input.files[i]);
        }
    }
}
