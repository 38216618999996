document.addEventListener('DOMContentLoaded', function (e) {
    const form = document.getElementById('nous_ecrire');
    if (null !== form) {
        const fv = FormValidation.formValidation(
            form,
            {

                fields: {
                    'news_letter[telBureau]': {
                        validators: {
                            phone: {
                                country: function () {
                                    return form.querySelector('[name="news_letter[country]"]').value;
                                },
                                // message: 'The value is not a valid phone number'
                            }
                        }
                    },
                    'news_letter[commentaire]': {
                        validators: {
                            stringLength: {
                                max: 144,
                                message: 'Le message ne peux contenir plus de 144 caractères.'
                            }
                        }
                    }
                },

                locale: 'fr_FR',
                localization: FormValidation.locales.fr_FR,

                plugins: {
                    declarative: new FormValidation.plugins.Declarative({
                        html5Input: true,
                    }),
                    trigger: new FormValidation.plugins.Trigger(),
                    submitButton: new FormValidation.plugins.SubmitButton(),
                    defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
                    // Support the form made in Bootstrap 4
                    bootstrap: new FormValidation.plugins.Bootstrap(),
                    excluded: new FormValidation.plugins.Excluded(),
// Show the feedback icons taken from FontAwesome
                   /* recaptcha3: new FormValidation.plugins.Recaptcha3({
                        action: 'submit',
                        // Replace with your verification back-end URL
                         backendVerificationUrl: '/recaptacha',
                        element: 'captchaContainer',
                        message: 'The captcha is not valid or expired',
                        // Replace with the site key provided by Google
                        siteKey: '6LeFFqsZAAAAAP-8UQf5KTIs2seZwm9hbTXRyydy',
                    }),*/
                    icon: new FormValidation.plugins.Icon({
                        valid: 'fas fa-check',
                        invalid: 'fas fa-times',
                        validating: 'fas fa-refresh',
                    }),
                },
            }
        );
        form.querySelector('[name="news_letter[country]"]').addEventListener('change', function () {

            // Revalidate the phone number field whenever user changes the country
            fv.revalidateField('news_letter[telBureau]');
        });
    }
});